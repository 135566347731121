import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'
import { useStores } from '@hooks/useStores'
import { useObserver } from "mobx-react-lite"

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
// import PageSectionParagraph from "@components/blocks/common/PageSectionParagraph"
import List from "@components/blocks/hotItems/List"
import OfficalWorks from "@components/blocks/index/OfficialWorks"
// import Slider from "@components/blocks/prodcut/Slider"

import css from "@css/components/blocks/common/Page.module.styl"
import ContentBox from "@components/blocks/hotItems/ContentBox"

export default function Prodcut(props) {
	const { t } = useTranslation()
	// const { appStore } = useStores()
	// const [currentLang] = useObserver(() => [
	// 	appStore.currentLang
	// ])

	const { thumbnail, hotItems, prodcutStoreArchive } = props.data
	return (
		<React.Fragment>
			<SEO title={'HOT ITEMS'} />
			<main className={css.container} id="main">
				<PageHeader l1="HOT ITEMS" l2={t('hotitems__headerTitle2')} />
				<div className={css.content}>
					<div className={css.prodcut}>
						<section className={css.section}>
							<ContentBox/>
						</section>
						<section className={css.section}>
							{/* <PageSectionTitle l1={t('prodcut__sectionTitle1_1')} l2={t('prodcut__sectionTitle1_2')} /> */}
							<List data={hotItems.edges} />
						</section>
						{/* <section className={css.section}>
							<PageSectionTitle l1={t('prodcut__sectionTitle2_1')} l2={t('prodcut__sectionTitle2_2')} />
							<ProdcutList data={prodcutStoreArchive.edges}/>
						</section> */}
						<section className={css.section}>
							<PageSectionTitle l1={t('index__supportTitleOfficialWorks')} l2={t('index__officialWorksTitle2')} />
							<OfficalWorks contentMode />
						</section>
					</div>
				</div>
			</main>
			<FooterSns />
			<Footer />
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        thumbnail : file(relativePath: { eq: "sample__img__thumbnail02@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
		hotItems : allWordpressWpHotItems (
			sort: { fields: date, order: DESC }
		){
            edges {
                node {
					id
					status
                    title
                    content
                    date(formatString: "YYYY-MM-DD")
                    acf {
						item_label
						item_url
						item_category
					}
					featured_media {
            			source_url
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                }
            }
        }
    }
`