import React from "react"

import css from "@css/components/blocks/common/PageSectionTitle.module.styl"

export default function PageHeader({l1, l2})
{
    return(
        <div className={css.container}>
            <h3 className={css.title}>
                <span className={css.l1}>{l1}</span>
                <span className={css.l2}>{l2}</span>
            </h3>
        </div>
    )
}
