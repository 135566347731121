import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Link from "@components/Link"

import css from "@css/components/blocks/hotItems/List.module.styl"
// import LinkSvg from "@images/Common__icn__link--green.svg"

export default function ProdcutList({data, thumbnail_brank})
{
    const q = useStaticQuery(graphql`
        query {
            link : file(relativePath: { eq: "Common__icn__link--green.svg" }) {
                publicURL
            }
        }
    `)
    return(
        <React.Fragment>
        { data.length !== 0 ?
        <ul className={css.container}>
            {
                data.map(( {node}, i)=>{
                    if( !node ){
                        return <div/>
                    }
                    if( node.status !== "publish"){
                        return
                    }

                    let { title, date, featured_media, acf } = node
                    let { item_label, item_url, item_category} = acf
                    return(
                        <li className={css.item} key={i}>
                            <figure className={css.thumbnail}>
                                { featured_media ?
                                    <FluidImage data={featured_media.localFile}/>
                                    :
                                    thumbnail_brank && <FluidImage data={thumbnail_brank}/>
                                }
                            </figure>
                            <div className={css.detail}>
                                {/* <p className={css.category}>
                                    {item_category}
                                </p> */}
                                <p className={css.title}>
                                    {title}
                                </p>
                                <p className={css.info}>
                                    {
                                        item_url ?
                                            <Link className={css.button} to={item_url} target="blank">
                                                {item_label}<span className={css.icon}>
                                                    <img src={q.link.publicURL}/>
                                                </span>
                                            </Link>
                                            :
                                            <span className={css.close}>{item_label}</span>
                                    }
                                </p>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
        :
        <ul className={css.container}>
            <li className={css.nopost}>投稿はまだありません。</li>
        </ul>
        }
        </React.Fragment>
    )
}
