import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import {useTranslation} from 'react-i18next'
import { useURL } from '@hooks/useURL'

import Link from "@components/Link"


import css from "@css/components/blocks/hotItems/ContentBox.module.styl"


export default function ContentBox({contentMode})
{
    const { t } = useTranslation()
    const q = useStaticQuery(graphql`
        query {
            shopImg : file(relativePath: { eq: "HotItems__img__shop@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 480) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const urls = useURL()
    return (
        <div className={css.card}>
            <figure className={css.thumbnail2}>
                <div className={css.inner}>
                    <FluidImage data={q.shopImg} />
                </div>
            </figure>
            <div className={css.detail}>
                <h4 className={css.title} dangerouslySetInnerHTML={{ __html: t('hotitems__shopTitle') }}></h4>
                <p className={css.text} dangerouslySetInnerHTML={{__html: t('hotitems__shopText') }}>
                </p>
                <div className={css.pc}>
                    <p className={css.buttons}>
                        <Link className={css.button_shop} to={urls.shop} target="_blank" isHtml={true}>{t('hotitems__shopButton')}</Link>
                    </p>
                </div>
            </div>
            <div className={css.sp}>
                <p className={css.buttons}>
                    <Link className={css.button_shop} to={urls.shop} target="_blank" isHtml={true}>{t('hotitems__shopButton')}</Link>
                </p>
            </div>
        </div>
    )
}